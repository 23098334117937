<template>
  <v-row v-if="interviews.length > 0">
    <!-- <pre>
  {{interviews}}
</pre> -->

    <v-col
      v-for="(interview, index) in interviews"
      :key="index"
      cols="12"
      sm="6"
      md="4"
      lg="3"
    >
      <v-skeleton-loader
        elevation="2"
        v-if="$apollo.loading"
        v-bind="interviews"
        type="article, actions"
      ></v-skeleton-loader>
      <interview-card :interview="interview" v-else />
    </v-col>
  </v-row>
</template>

<script>
// import { GET_INTERVIEWS_CANDIDATE_QUERY } from './../graphql/Query'
import InterviewCard from "./InterviewCard.vue";
export default {
  components: { InterviewCard },
  props: {
    interviews: Array,
  },
  name: "InterviewList",
};
</script>