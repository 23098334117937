import gql from 'graphql-tag'

export const GET_INTERVIEWS_CANDIDATE_QUERY = gql`
  query GetInterviewsCandidate {
    interviews: getInterviewsCandidate {
      id
      candidacies {
        date
        status
        time
      }
      entity {
        name
      }
      vacancy {
        id
        title
      }
    }
  }
`