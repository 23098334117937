<template>
  <v-hover
    v-slot:default="{ hover }"
    open-delay="150"
  >
    <v-card
      :elevation="hover ? 16 : 2"
      max-width="374"
    >
    <!-- <pre>
      {{interview.vacancy}}
    </pre> -->
      <v-system-bar
        window
        color="white"
      >
        <v-spacer></v-spacer>
        <v-btn
          tile
          x-small
          dense
          
          :color="statusColor(interview.candidacies[0].status)"
        >
          {{ status(interview.candidacies[0].status) }} 
        </v-btn>
      </v-system-bar>

      <v-list-item two-line class="">
        <v-list-item-content>
          <!-- <v-list-item-subtitle class="caption">Entidade</v-list-item-subtitle> -->
          <v-list-item-title class="text-h6">
            {{ interview.entity ? interview.entity.name : "" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line class="" style="margin-top:-5%">
        <v-list-item-content>
          <v-list-item-subtitle class="caption font-weight-medium">{{$t('Job_title')}}</v-list-item-subtitle>
          <v-list-item-title class="subtitle  black--text">
            {{ interview.vacancy ? interview.vacancy.title : '' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-chip-group active-class="amber darken-1 white--text" column>
          <v-chip small>{{ localDate(interview.candidacies[0].date) }} {{ interview.candidacies[0].time }}</v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import statusMixins from '@/mixins/status'
export default {
  name: 'InterviewCard',
  mixins: [
    statusMixins
  ],
  props: {
    interview: Object
  },
  methods: {
    localDate (date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale);
    },
  }
}
</script>